import { gql } from "@apollo/client";

export const GET_PAYMENT_METHOD= gql`
query NewQuery {
    paymentGateways {
        nodes {
          description
          icon
          id
          title
        }
      }
}
  `