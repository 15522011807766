import { gql } from "@apollo/client"

export const REMOVE_COUPONS = gql`
mutation removeCoupons($input: RemoveCouponsInput!) {
  removeCoupons(input: $input) {
    cart {
      discountTax
      discountTotal
      displayPricesIncludeTax
      feeTax
      isEmpty
      shippingTax
      shippingTotal
      subtotal
      subtotalTax
      total
      totalTax
    }
    clientMutationId
  }
}
`