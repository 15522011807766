import { useQuery,useMutation } from "@apollo/client"
import React, { useEffect } from "react"
import { GET_PAYMENT_METHOD } from "../../graphql/queries/paymentMethod"
import {REMOVE_COUPONS} from "../../graphql/mutations/coupon/removeCoupons.js"
import Loading from "../../components/Loading"
import { UPDATE_SHIPPING_METHOD } from "../../graphql/mutations/updateShippingMethod"
import { toast } from "react-toastify"
const YourOrder = ({
  setValueCheckBox,
  setValuePayment,
  valuePayment,
  cartDetail,
  shippingMethodLoading,
  refetch,
  cartLoading,
  setShippingCost,
  setShippingName,
  valueCheckBox
}) => {
  const { data: payment_method } = useQuery(GET_PAYMENT_METHOD)
  const [removeCoupon,{loading:loadingRemoveCoupon}] = useMutation(REMOVE_COUPONS)
  const items_cart = cartDetail ? cartDetail.contents.nodes : null
  const [updateShippingMethod,{loading:updateShippingLoading}] = useMutation(UPDATE_SHIPPING_METHOD,{
    onCompleted() {
      refetch()
    }
  })
  const handleRemoveCoupon = code => {
    const tempCode = code.toLowerCase()
    removeCoupon({
      variables: {
        input: {
          codes: [tempCode]
        }
      },
      onCompleted: () => {
        refetch()
      },
      onError: (e) => {
        toast.error(e)
      }
    })
  }
  useEffect(() => {
    if (payment_method) {
      setValuePayment(payment_method?.paymentGateways?.nodes[0].id)
    }
  }, [payment_method])
  useEffect(() => {
    if(valueCheckBox) {
      const temp = cartDetail?.availableShippingMethods[0]?.rates.find(e => e.id === valueCheckBox)
      if(temp) {
        setShippingName(temp.label)
        setShippingCost(parseFloat(temp.cost))
      }
    }
  },[valueCheckBox])
  return (
    <div className="order">
      {(loadingRemoveCoupon || cartLoading || updateShippingLoading) && <Loading/>}
      <h2 className="title-order">Your order</h2>
      <p className="sub-des">
        If you only see the Shipping option of Local Pickup,&nbsp; please ensure you have entered
        the complete address.
      </p>
      <table className="table-review">
        <thead>
          <tr>
            <th className="th-first-row">Product</th>
            <th className="th-first-row">Subtotal</th>
          </tr>
        </thead>
        <tbody>
          {items_cart?.map((el, index) => (
            <tr key={index}>
              <td>
                {el.product.node.name}
                <strong className="qty">× {el.quantity}</strong>
              </td>
              <td>{el.subtotal}</td>
            </tr>
          ))}
            {cartDetail?.appliedCoupons?.map(item => (
              <tr key={item.code} className="cart-discount coupon-devtest">
                <th>Coupon: {item.code}</th>
                <td data-title={`Coupon: ${item.code}`}>
                  -<span className="woocommerce-Price-amount amount">{item.discountAmount}</span>
                  <a className="pointer" onClick={() => handleRemoveCoupon(item.code)}>
                    {" "}
                    [Remove]
                  </a>
                </td>
              </tr>
            ))}
          <tr>
            <th>Subtotal</th>
            <td className="td-bold">{cartDetail ? cartDetail?.total : ""}</td>
          </tr>
          <tr>
            <th>Shipping</th>
            <td className="td-bold">
              <input
                type="hidden"
                name="shipping_method[0]"
                data-index="0"
                className="shipping_method"
              />
              <label htmlFor="">
                <div>
                  {shippingMethodLoading ? (
                    <div>Loading...</div>
                  ) : (
                    <>
                      {cartDetail
                        ? cartDetail?.availableShippingMethods[0]?.rates?.map((e, index) => (
                            <div key={index}>
                              <input
                                onChange={ev => {
                                  setValueCheckBox(ev.target.value)
                                  updateShippingMethod({variables: {
                                    input:{
                                      shippingMethods:[ev.target.value]
                                    }
                                  }})
                                }}
                                type="radio"
                                name="vehicle1"
                                value={e.id}
                                checked={valueCheckBox === e.id}
                                id={e.id}
                              />
                              <label htmlFor="vehicle1">
                                {e.label}: {parseInt(e.cost) > 0 ? e.cost : ""}
                              </label>
                              <br />
                            </div>
                          ))
                        : ""}
                    </>
                  )}
                </div>
              </label>
            </td>
          </tr>
          <tr>
            <th>Payment</th>
            <td className="td-bold">
              <input
                type="hidden"
                name="shipping_method[0]"
                data-index="0"
                className="shipping_method"
              />
              <label htmlFor="">
                <div>
                  {payment_method
                    ? payment_method?.paymentGateways?.nodes?.map((e, index) => (
                        <div key={index}>
                          <input
                            onChange={e => {
                              setValuePayment(e.target.value)
                            }}
                            type="radio"
                            name="payment"
                            value={e.id}
                            id={e.id}
                            checked={e.id === valuePayment ? "checked" : ""}
                          />
                          <label htmlFor="payment">{e.title}</label>
                          <br />
                        </div>
                      ))
                    : ""}
                </div>
              </label>
            </td>
          </tr>
          <tr>
            <th>Total</th>
            <td className="td-bold">
              {/* {cartDetail && shippingCost ? `$${(Number(cartDetail?.total.replace("$","")) + shippingCost).toFixed(2)}` : ""} */}
              {cartDetail  ? `$${Number(cartDetail?.total.replace("$","")).toFixed(2)}` : ""}
              </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default YourOrder
