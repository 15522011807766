import { gql } from "@apollo/client";

export const UPDATE_SHIPPING_METHOD = gql`
mutation UPDATE_SHIPPING_METHOD($input:UpdateShippingMethodInput!) {
    updateShippingMethod(
      input: $input
    ) {
      cart {
        total
      }
    }
  }
`